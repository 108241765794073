export const dataIndex = {
    txt_content_btn: "Ver Mas",
    txt_content_banner:
        "La Universidad Pública de El Alto, forjando profesionales para el desarrollo de Bolivia.",
    txt_content_banner_two:
        "Forjando mentes brillantes en la Universidad Pública de El Alto, en pos de un Bolivia más fuerte y equitativo.",
    txt_content_banner_three:
        "Nuestro compromiso en la Universidad Pública de El Alto es cultivar talento que transforme el horizonte de Bolivia.",

    //CONVOCATORIAS, COMUNICADOS, AVISOS
    txt_content_convocatorias:
        "Lo Ultimo de Convocatorias, Comunicados y Avisos",
    txt_content_cursos: "Lo Ultimo de Cursos y Seminarios",

    //AUTORIDADES
    txt_content_autoridades: "Autoridades",

    //LINKS EXTERNOS
    txt_content_links_externos: "Links Externos",
    txt_content_links_btn: "Ver Mas",
    txt_content_btn_cat: "Categorías",

    //SOBRE LA INSTITUCION
    txt_content_sobre_la_institucion: "Sobre la Institución",
    txt_content_texto:
        "Cada día en la universidad es una oportunidad para aprender algo nuevo y marcar la diferencia.",
};
export const dataCategory = {
    txt_content_btn: "Ver Mas",
    txt_content_banner_category:
        "La UPEA es una universidad que está comprometida con el desarrollo de una sociedad justa y equitativa. Es una universidad que promueve la igualdad de oportunidades y la inclusión social.",
};

export const dataCategoryDetail = {
    txt_content_btn: "Ver Mas",
    txt_content_banner_detail:
        "Nuestros estudiantes son la inspiración que impulsa a la UPEA hacia la excelencia educativa.",
};

export const dataContact = {
    txt_content_contact: "CONTACTO",
    txt_content_banner_contact:
        "La UPEA es una universidad de excelencia, que forma profesionales competentes y comprometidos con el desarrollo de su país.",
};

export const dataAbout = {
    txt_content_about: "SOBRE NOSOTROS",
    txt_content_banner_about:
        "La UPEA es una universidad que trabaja por el desarrollo de Bolivia.",
};

export const dataAcademia = {
    txt_content_banner_academia:
        "La UPEA es una universidad inclusiva, que brinda oportunidades a todos los estudiantes, independientemente de su origen social o económico.",
    txt_content_btn: "Descargar PDF",
    txt_content_calendario:
        "El calendario académico de la Universidad Pública de El Alto está diseñado para ofrecer a los estudiantes una estructura clara de fechas importantes, como el inicio y finalización de semestres, períodos de inscripción, exámenes parciales y finales, vacaciones y otros eventos relevantes. Los calendarios se publican con anticipación para que los estudiantes puedan planificar sus actividades académicas con tiempo suficiente.",
    txt_content_horario:
        "El horario académico de la carrera está diseñado para proporcionar a los estudiantes un equilibrio entre clases teóricas y prácticas. Las asignaturas se distribuyen a lo largo de la semana, permitiendo a los estudiantes participar en diversas actividades educativas. Los horarios son flexibles y se adaptan a las necesidades de los estudiantes y la disponibilidad de profesor",
    txt_content_plan_de_estudio:
        "El plan de estudio de la carrera ofrece una sólida formación en las áreas fundamentales de la disciplina, así como opciones de especialización. Los estudiantes cursarán materias obligatorias y electivas que les permitirán adquirir conocimientos y habilidades necesarios para su desarrollo profesional. El plan se actualiza periódicamente para reflejar los avances en la materia y las demandas del mercado laboral.",
    txt_content_reglamento: "Reglamento y Mod. de Graduación",
};

export const dataInstitucion = {
    txt_content_banner_institucion:
        "La UPEA es una universidad que se caracteriza por su compromiso con la justicia social y la lucha por los derechos humanos.",
};

export const dataVerMas = {
    txt_content_banner_ver_mas:
        "La UPEA es una universidad que se caracteriza por su compromiso con la justicia social y la lucha por los derechos humanos.",
};

export const dataKey = {
    CLAVE_ENCRYPTACION: "UniversidadPublicaDeElAlto",
};

export const dataImagesStatic = {
    BgOne: "/assets/images/bg.jpg",
    BgTwo: "/assets/images/bg1.jpg",
    BgThree: "/assets/images/bg2.png",
    BgFour: "/assets/images/bg3.jpg",
    BgConvocatorias: "/assets/images/convocatorias.jpg",
    BgComunicados: "/assets/images/comunicados.jpg",
    BgAvisos: "/assets/images/avisos.png",
    BgCursos: "/assets/images/cursos.jpg",
    BgSeminarios: "/assets/images/seminarios.jpg",
    BgServicios: "/assets/images/servicios.jpg",
    BgOfertasAcademicas: "/assets/images/ofertasacademicas.jpg",
    BgPublicaciones: "/assets/images/publicaciones.jpg",
    BgGacetas: "/assets/images/gacetas.jpg",
    BgEventos: "/assets/images/eventos.jpg",
    BgVideos: "/assets/images/videos.jpg",
};
export const staticData = {
    // HEADER ========================
    btn_content_admin: "Ingresar",

    // DATA
    dataIndex,

    // CATEGORY
    dataCategory,

    // CATEGORY DETAIL
    dataCategoryDetail,

    // CLAVE DE ENCRYPTACION
    dataKey,

    // CONTACT
    dataContact,

    // ABOUT
    dataAbout,

    // ACADEMIA
    dataAcademia,

    // INSTITUCION
    dataInstitucion,

    // FOOTER
    txt_content_footer:
        "La UPEA es una universidad inclusiva, que brinda oportunidades a todos los estudiantes, independientemente de su origen social o económico",

    // IMAGENES STATICAS
    dataImagesStatic,

    // VER MAS BTN
    dataVerMas,
};
